import gql from "graphql-tag"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"

export const basicProblemFragment = gql`
  fragment basicProblemFragment on Problem {
    _id
    name
    slug
    type
    isArchived
    isIndoor
    archivedAt
    setterGrade
    grade
    gradeColor
    gradeName
    points
    totalAscents
    createDate
    publishedAt
    picture
    grades {
      user
      grade
    }
    quality
    qualities {
      user
      stars
    }
    popMedia {
      ...basicMediaFragment
    }
    location {
      coordinates
    }
    color
    gym {
      _id
      slug
      isShowingGrades
      isShowingOutdoorRouteGradeForm
      isOverlappingGrades
      hasScanners
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
    }
  }
  ${basicMediaFragment}
`

export default { basicProblemFragment }
