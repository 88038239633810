import * as React from "react"
import {
  faBuilding,
  faCalendarAlt,
  faChartBar,
  faChartLine,
  faFileInvoiceDollar,
  faGridHorizontal,
  faPencilAlt,
  faPrint,
  faUserChart,
  faUserShield,
  faWatchFitness,
} from "@fortawesome/pro-light-svg-icons"
import Sidebar from "../../common/components/Sidebar"
import { Gym, User } from "src/gql/types/graphql"
import { useAtom } from "jotai"
import { isSidebarOpenAtom } from "../../common/atoms/sidebar"
import SortMenu from "src/app/common/components/SortMenu"
import { currentGymAtom, GymAtomProps } from "../atoms/gyms"
import { GET_GYMS_MANAGED_AT } from "../graphql/gyms"
import { useQuery } from "@apollo/client"
import { useRouter } from "next/router"
import { Box, Flex, Heading, Tag, TagLabel } from "@chakra-ui/react"
import { useAuth } from "src/app/common/contexts/AuthContext"

export interface IGymSidebarProps {
  pathname?: string
  isHidingHeader?: boolean
  isHidingFooter?: boolean
}

export default function GymSidebar({
  isHidingHeader,
  isHidingFooter,
}: IGymSidebarProps) {
  const router = useRouter()
  const { loggedInUser } = useAuth()
  const [isSidebarOpen, setIsSidebarOpen] = useAtom(isSidebarOpenAtom)
  const [currentGym, setCurrentGym] = useAtom(currentGymAtom)
  const {
    loading,
    data = {},
    error,
  } = useQuery(GET_GYMS_MANAGED_AT, {
    skip: !loggedInUser,
  })
  if (error || loading) {
    return null
  }
  const gyms: [Gym] = data?.getGymsManagedAt
  const gym = currentGym || gyms?.[0]
  const canEdit =
    loggedInUser &&
    (loggedInUser?.gymsManaged?.includes(gym._id) || loggedInUser.isAdmin)
  const isHeadSetter =
    loggedInUser &&
    (loggedInUser?.gymsHeadSetterAt?.includes(gym._id) || loggedInUser.isAdmin)
  if (!canEdit && !isHeadSetter) {
    return null
  }
  const pathname = window.location.pathname
  const gymOptions = gyms.map((g) => ({
    ...g,
    value: g._id,
    label: g.name,
  }))
  const hasManyGyms = gyms.length > 1
  if (!gym) {
    return null
  }
  return (
    <Sidebar
      isHidingHeader={isHidingHeader}
      isHidingFooter={isHidingFooter}
      title={hasManyGyms ? null : gym.name}
      titleHref={hasManyGyms ? null : `/gyms/${gym.slug}`}
      headerComponent={
        hasManyGyms ? (
          <SortMenu
            isText
            placeholder="All Gyms"
            options={gymOptions}
            value={gym?._id}
            onChange={(initVal) => {
              const val = initVal === "all-gyms" ? undefined : initVal
              const newGym = val ? gyms.find((g) => g._id === val) : null
              setCurrentGym(newGym)
              const newPath = pathname.includes(`/${gym._id}`)
                ? pathname.replace(gym._id, val)
                : pathname.replace(gym.slug, newGym.slug)
              router.push(newPath)
            }}
            buttonProps={{
              size: "md",
              flex: 1,
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              mt: 5,
              textAlign: "left",
            }}
          />
        ) : null
      }
      isOpen={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
      sections={
        (!!canEdit || !!isHeadSetter) && [
          {
            items: [
              ...(canEdit
                ? [
                    ...(hasManyGyms
                      ? [
                          {
                            title: `View ${gym.name}`,
                            icon: faBuilding,
                            href: "/gyms/" + gym.slug,
                          },
                        ]
                      : []),
                    {
                      title: `Edit ${gym.name}`,
                      icon: faPencilAlt,
                      href: "/edit/gym/" + gym.slug,
                    },
                    {
                      title: `User Permissions`,
                      icon: faUserShield,
                      href: "/edit/gympermissions/" + gym.slug,
                    },
                    {
                      title: `Gym Analytics Dashboard`,
                      RightComponent: gym.hasPremiumPlan ? null : (
                        <Tag
                          bg="brand.whiteColor"
                          borderColor="brand.orangeColor"
                          borderWidth={1}
                        >
                          <TagLabel
                            color="brand.orangeColor"
                            fontStyle="italic"
                          >
                            Premium
                          </TagLabel>
                        </Tag>
                      ),
                      icon: faChartLine,
                      href: "/dashboards/" + gym._id,
                    },
                    {
                      title: `Route Setting Analytics`,
                      RightComponent: gym.hasPremiumPlan ? null : (
                        <Tag
                          bg="brand.whiteColor"
                          borderColor="brand.orangeColor"
                          borderWidth={1}
                        >
                          <TagLabel
                            color="brand.orangeColor"
                            fontStyle="italic"
                          >
                            Premium
                          </TagLabel>
                        </Tag>
                      ),
                      icon: faUserChart,
                      href: "/settinganalytics/" + gym._id,
                    },
                    {
                      title: "Member Dashboard",
                      RightComponent: gym.hasPremiumPlan ? null : (
                        <Tag
                          bg="brand.whiteColor"
                          borderColor="brand.orangeColor"
                          borderWidth={1}
                        >
                          <TagLabel
                            color="brand.orangeColor"
                            fontStyle="italic"
                          >
                            Premium
                          </TagLabel>
                        </Tag>
                      ),
                      icon: faGridHorizontal,
                      href: "/member-dashboards/" + gym.slug,
                    },
                    // ...(loggedInUser.isGymOwner
                    //   ? [
                    //       {
                    //         title: "Manage Your Subscription",
                    //         icon: faFileInvoiceDollar,
                    //         href: "/billing-settings",
                    //       },
                    //     ]
                    //   : []),
                  ]
                : []),
              ...(isHeadSetter
                ? [
                    {
                      title: "Route Setting Management",
                      items: [
                        {
                          title: "Setting Scheduler",
                          RightComponent: gym.hasPremiumPlan ? null : (
                            <Tag
                              bg="brand.whiteColor"
                              borderColor="brand.orangeColor"
                              borderWidth={1}
                            >
                              <TagLabel
                                color="brand.orangeColor"
                                fontStyle="italic"
                              >
                                Premium
                              </TagLabel>
                            </Tag>
                          ),
                          icon: faCalendarAlt,
                          href: "/setting-scheduler",
                        },
                        {
                          title: "Setting Distribution & Tagging",
                          icon: faChartBar,
                          href: "/setting-distribution/" + gym.slug,
                        },
                        {
                          title: "Print Climb Labels",
                          RightComponent: gym.hasPremiumPlan ? null : (
                            <Tag
                              ml="auto"
                              bg="brand.whiteColor"
                              borderColor="brand.orangeColor"
                              borderWidth={1}
                            >
                              <TagLabel
                                color="brand.orangeColor"
                                fontStyle="italic"
                              >
                                Premium
                              </TagLabel>
                            </Tag>
                          ),
                          icon: faPrint,
                          href: "/gym-labels/" + gym.slug,
                        },
                      ],
                    },
                  ]
                : []),
              {
                title: "View active scanners",
                // RightComponent: gym.hasScanners ? null : (
                //   <Tag
                //     bg="brand.whiteColor"
                //     borderColor="brand.orangeColor"
                //     borderWidth={1}
                //   >
                //     <TagLabel color="brand.orangeColor" fontStyle="italic">
                //       Pebble Connect
                //     </TagLabel>
                //   </Tag>
                // ),
                icon: faWatchFitness,
                href: "/gym-scanners/" + gym.slug,
              },
            ],
          },
        ]
      }
    />
  )
}
