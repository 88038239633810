import React from "react"
import { Button, Flex } from "@chakra-ui/react"
import Card from "../../common/components/Card"
import Modal from "react-modal"

import styles from "./css/ConfirmModal.module.css"

const ModalCard = (props) => {
  const {
    isOpen,
    children,
    closeString = "Close",
    closeModal,
    contentStyle = {},
    isThin = false,
    ActionBtn = null,
    isHidingClose = false,
  } = props

  return (
    <Modal
      onClick={closeModal}
      isOpen={isOpen === true}
      className={
        styles["confirm-modal"] +
        ` modal-container ${isThin ? "" : "full"} flex-center`
      }
      overlayClassName="modal-overlay"
      onRequestClose={closeModal}
      ariaHideApp={false}
      contentLabel={"modal card"}
      style={{ content: contentStyle }}
    >
      <Card>
        {children}
        <Flex mt={5} justifyContent="flex-end">
          {!isHidingClose && (
            <Button variant="secondary" onClick={closeModal}>
              {closeString}
            </Button>
          )}
          {ActionBtn}
        </Flex>
      </Card>
    </Modal>
  )
}

export default ModalCard
