import * as React from "react"
import { useLazyQuery } from "@apollo/client"
import {
  GET_DOWNLOADABLE_COMP_REPORT,
  GET_DOWNLOADABLE_COMP_SCORES,
} from "../../gyms/graphql/comps"
import {
  faPencilAlt,
  faPlusCircle,
  faTable,
  faTrophy,
  faUsersCog,
} from "@fortawesome/pro-light-svg-icons"
import Sidebar from "../../common/components/Sidebar"
import { Comp, CompSet, Gym, User } from "src/gql/types/graphql"
import { useAtom } from "jotai"
import { isSidebarOpenAtom } from "../../common/atoms/sidebar"
import { useRouter } from "next/router"
import { downloadCompetitorListWithScores } from "../../utilities/comps"
import { downloadCompReport } from "../../utilities/comps"
import MenuButton from "../../common/components/MenuButton"
import EditCompRoundScoresModal from "../../gyms/components/EditCompRoundScoresModal"
import { Portal } from "@chakra-ui/react"
import { useAuth } from "src/app/common/contexts/AuthContext"

export interface IGymSidebarProps {
  comp: Comp
  setAlertMessage: (message: string, isError?: boolean) => void
  isHidingHeader?: boolean
  isHidingFooter?: boolean
}

export default function CompSidebar({
  comp,
  setAlertMessage,
  isHidingHeader = false,
  isHidingFooter = false,
}: IGymSidebarProps) {
  const router = useRouter()
  const { loggedInUser } = useAuth()
  const [isSidebarOpen, setIsSidebarOpen] = useAtom(isSidebarOpenAtom)
  const [isEditingScores, setIsEditingScores] = React.useState(false)
  const [currentCompSet, setCurrentCompSet] = React.useState<CompSet>()
  const [isLoadingCompScores, setIsLoadingCompScores] = React.useState(false)
  const [downloadCompScores, { data, loading, error }] = useLazyQuery(
    GET_DOWNLOADABLE_COMP_SCORES,
    {
      variables: { slug: comp.slug },
      fetchPolicy: "network-only",
    },
  )

  React.useEffect(() => {
    if (data && isLoadingCompScores) {
      setIsLoadingCompScores(false)
      // TODO: download file
      downloadCompetitorListWithScores(data.comp)
    }
  }, [data, isLoadingCompScores])

  const [isLoadingReport, setIsLoadingReport] = React.useState(false)
  const [
    downloadReport,
    { data: reportData, loading: reportLoading, error: reportError },
  ] = useLazyQuery(GET_DOWNLOADABLE_COMP_REPORT, {
    variables: { slug: comp.slug },
    fetchPolicy: "network-only",
  })
  React.useEffect(() => {
    if (reportData && isLoadingReport && !loading) {
      setIsLoadingReport(false)
      // TODO: download file
      downloadCompReport(reportData.comp)
    }
  }, [isLoadingReport, loading, reportData])

  const isManager =
    loggedInUser &&
    (loggedInUser?.gymsManaged?.includes(comp.gyms?.[0]?._id ?? comp.gym._id) ||
      loggedInUser.isAdmin)
  if (!isManager) {
    return null
  }

  return (
    <>
      <Sidebar
        isHidingHeader={isHidingHeader}
        isHidingFooter={isHidingFooter}
        title={comp?.name}
        titleHref={`/comps/${comp.slug}`}
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        sections={
          !!isManager && [
            {
              items: [
                {
                  title: `Edit ${comp.name}`,
                  icon: faPencilAlt,
                  href: "/edit/comp/" + comp.slug,
                },
                {
                  title: `Manage Competitors`,
                  icon: faUsersCog,
                  href: "/manage-competitors/" + comp.slug,
                },
                {
                  title: `Download Scores`,
                  icon: faTable,
                  onClick: () => {
                    setIsLoadingCompScores(true)
                    downloadCompScores()
                  },
                  isLoading: isLoadingCompScores,
                },
                {
                  title: `Download Final Report`,
                  icon: faTable,
                  onClick: () => {
                    setIsLoadingReport(true)
                    downloadReport()
                  },
                  isLoading: isLoadingReport,
                },
                {
                  title: "Comp Rounds",
                  items: [
                    ...(comp?.compSets ?? []).map((compSet: CompSet) => {
                      const hasBonusActivities =
                        compSet?.bonusActivities?.length > 0
                      return {
                        title: `Edit ${compSet.name}`,
                        icon: faTrophy,
                        href: `/edit/comp-set/${compSet.slug}`,
                        RightComponent: (
                          <MenuButton
                            options={[
                              {
                                label: "Edit a Competitor's Scores",
                                value: "edit-scores",
                              },
                              ...(hasBonusActivities
                                ? [
                                    {
                                      label: "Add a Competitor Bonus Activity",
                                      value: "add-bonus-activity",
                                    },
                                  ]
                                : []),
                            ]}
                            onSelect={(obj) => {
                              if (obj.value === "edit-scores") {
                                setCurrentCompSet(compSet)
                                setIsEditingScores(true)
                              }
                              if (obj.value === "add-bonus-activity") {
                                router.push({
                                  pathname: `/edit-competitor-bonus/${compSet.slug}`,
                                })
                              }
                            }}
                            isIcon
                          />
                        ),
                      }
                    }),
                    {
                      title: "Add a Comp Round",
                      icon: faPlusCircle,
                      href: `/create/comp-set/comps/${comp.slug}`,
                    },
                  ],
                },
              ],
            },
          ]
        }
      />
      {!!isEditingScores && !!currentCompSet && (
        <Portal>
          <EditCompRoundScoresModal
            comp={comp}
            compSet={currentCompSet}
            isModalOpen={isEditingScores}
            closeModal={() => setIsEditingScores(false)}
            setAlertMessage={setAlertMessage}
            isManager
          />
        </Portal>
      )}
    </>
  )
}
