import Tooltip from "../../common/components/Tooltip"
import React from "react"
import {
  getGradeLabel,
  getGradeColor,
  getGradeImage,
} from "../../utilities/grades"

import styles from "./css/ClimbGrade.module.css"

const ClimbGrade = ({
  climb,
  gym: initGym = null,
  gradeType = null,
  dotSize = 40,
  isCenter = false,
  ttId = "",
  isBold = false,
}) => {
  const gym = initGym || climb.gym
  const isShowingGrades = !gym || gym?.isShowingGrades
  const grade = climb.setterGrade || climb.grade
  const gradeLabel = getGradeLabel(
    grade,
    climb.type,
    gradeType,
    gym,
    climb.gradeColor,
    climb.gradeName,
  )
  const gradeColor = gym
    ? climb.gradeColor ||
      getGradeColor(grade, gym?.[`${climb.type}GradeColors`])
    : null
  const gradeImage = getGradeImage(grade, gym?.[`${climb.type}GradeColors`])
  return (
    <span
      className={
        styles["wrapper"] + " " + (isCenter ? styles["wrapper-center"] : "")
      }
    >
      <span
        className={
          styles["grade"] +
          " " +
          (isCenter ? styles["grade-center"] : "") +
          " " +
          (!gradeColor ? styles["no-color"] : "")
        }
      >
        {gradeImage && (
          <img
            width={dotSize}
            height={dotSize}
            src={gradeImage}
            alt={gradeLabel}
          />
        )}
        {!!gradeColor && (
          <span
            style={
              isShowingGrades || !dotSize
                ? {
                    backgroundColor: gradeColor,
                  }
                : {
                    width: dotSize,
                    height: dotSize,
                    backgroundColor: gradeColor,
                  }
            }
            className={
              styles[isShowingGrades ? "grade-bkgrnd" : "grade-color-dot"]
            }
          />
        )}
        {(isShowingGrades || (!gradeImage && !gradeColor)) && (
          <span
            className={
              isBold ? styles["grade-label-strong"] : styles["grade-label"]
            }
          >
            {gradeLabel}
          </span>
        )}
      </span>
    </span>
  )
}
export default ClimbGrade
