export const genderOptions = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "nonBinary",
    label: "Non-Binary",
  },
  {
    value: "preferNone",
    label: "other",
  },
]
