import gql from "graphql-tag"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"

export const basicGymFragment = gql`
  fragment basicGymFragment on Gym {
    _id
    slug
    name
    type
    isHidden
    isBeta
    logo
    color
    numWeeksForOldClimb
    numWeeksForOldRoute
    numWeeksForOldProblem
    isFrenchGrades
    isHoldColorGrade
    isOverlappingGrades
    isShowingGrades
    hasScanners
    isShowingScannerPoints
    isShowingCustomGradesToSetters
    isShowingOutdoorRouteGradeForm
    isSendingSettingNotificationsBeforeSettingStarts
    canLinkToMRP
    hasPremiumPlan
    location {
      coordinates
    }
    popMedia {
      ...basicMediaFragment
    }
    headSetterUsers {
      _id
      slug
      email
      name
      firstName
      lastName
      gymsOwned
      gymsManaged
      gymsHeadSetterAt
      gymsSetterAt
      gymsMaxGradesSetAt {
        gymId
        problemGrade
        routeGrade
        canResetArea
      }
    }
    setterUsers {
      _id
      slug
      email
      name
      firstName
      lastName
      gymsOwned
      gymsManaged
      gymsHeadSetterAt
      gymsSetterAt
      gymsMaxGradesSetAt {
        gymId
        problemGrade
        routeGrade
        canResetArea
      }
    }
    settingColors {
      color
      name
    }
    routeGradeColors {
      color
      name
      minGrade
      maxGrade
      image
    }
    problemGradeColors {
      color
      name
      minGrade
      maxGrade
      image
    }
    hasMissingClimbEmails
  }
  ${basicMediaFragment}
`

export default {
  basicGymFragment,
}
