import { ApolloProvider } from "@apollo/client"
import React from "react"
import ErrorAlert from "../src/app/common/components/ErrorAlert"
import { extendTheme, ChakraProvider } from "@chakra-ui/react"
import {
  useApollo,
  useApolloNetworkStatus,
} from "../src/app/config/apolloClient"
import AppContainer from "../src/app/common/containers/App"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"

config.autoAddCss = false

// import css for components
import "../node_modules/react-datepicker/dist/react-datepicker.css"
import "../node_modules/rc-slider/assets/index.css"
import "../node_modules/react-image-crop/dist/ReactCrop.css"
import "../node_modules/mapbox-gl/dist/mapbox-gl.css"
import "../node_modules/react-rater/lib/react-rater.css"
import "../node_modules/material-components-web/dist/material-components-web.min.css"
import "../node_modules/react-slidedown/lib/slidedown.css"

import "../src/app/css/common/rc-overrides.css"
import "../src/app/css/common/select-overrides.css"
import "../src/app/css/common/map.css"
import "../src/app/css/common/colors.css"

import "../src/app/css/common/stripe.css"
import "../src/app/css/main.css"
import NoSsr from "../src/app/common/components/NoSSR"
import Head from "next/head"
import useChakraTheme from "../src/app/common/hooks/useChakraTheme"
import { isDarkModeAtom } from "../src/app/common/hooks/useColors"
import { useAtom } from "jotai"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import { useRouter } from "next/router"
import { isInEuropeanUnion } from "../src/mobile/Pebble/utilities/date"

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(
    process.env.NEXT_PUBLIC_POSTHOG_KEY ||
      "phc_djKi2cdbpDBTiVXbvlILlGW29UG2wfX8kNM6wX5QPnZ",
    {
      api_host: isInEuropeanUnion()
        ? "https://eu.i.posthog.com"
        : "https://us.i.posthog.com",
      person_profiles: "identified_only",
      enable_heatmaps: false,
      // Enable debug mode in development
      loaded: (posthog) => {
        if (process.env.NODE_ENV !== "production") posthog.debug()
      },
    },
  )
}

const App = ({ Component, pageProps }) => {
  const router = useRouter()

  const [isDarkMode] = useAtom(isDarkModeAtom)
  const apolloClient = useApollo(pageProps)
  const { chakraTheme } = useChakraTheme()
  const theme = extendTheme(chakraTheme)

  React.useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview")
    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  if (!apolloClient) {
    return null
  }
  return (
    <>
      <Head />
      <div className={`App ${isDarkMode ? "dark-mode" : ""}`}>
        <ChakraProvider theme={theme}>
          <ApolloProvider client={apolloClient}>
            <PostHogProvider client={posthog}>
              <AppContainer {...pageProps} Component={Component} />
              <NoSsr>
                <ErrorAlert useApolloNetworkStatus={useApolloNetworkStatus} />
              </NoSsr>
            </PostHogProvider>
          </ApolloProvider>
        </ChakraProvider>
      </div>
    </>
  )
}

export default App
