import _ from "lodash"
import { useMutation } from "@apollo/client"
import {
  CREATE_COMP_SCORE,
  EDIT_COMP_SCORE,
  GET_COMP_SCORE_FROM_CLIMB,
} from "../graphql/comps"
import { ProblemQuery } from "../../guides/graphql/problems"
import { RouteQuery } from "../../guides/graphql/routes"

export function useSaveCompScore({
  isEditing,
  compScore,
  climb,
  isSend,
  isMobile,
  compSet,
  competitorCode,
  competitorId,
  setMessage = () => null,
  returnFromSave,
}) {
  const [createCompScore] = useMutation(CREATE_COMP_SCORE, {
    refetchQueries: [
      {
        query: climb.type === "problem" ? ProblemQuery : RouteQuery,
        variables: { slug: climb.slug },
      },
    ],
  })
  const [editCompScore] = useMutation(EDIT_COMP_SCORE, {
    refetchQueries: [
      {
        query: climb.type === "problem" ? ProblemQuery : RouteQuery,
        variables: { slug: climb.slug },
      },
    ],
  })
  const mutation = isEditing ? editCompScore : createCompScore
  console.log("compScore: ", compScore.isCreatingSend)
  async function saveCompScore(isAutoSaving) {
    if (!isMobile) {
      setMessage("Saving Score...")
    }
    let scoreToSave = _.chain(compScore)
      .pick([
        "_id",
        "highpoint",
        "numAttempts",
        "userId",
        "isCreatingSend",
        "isRemovingSend",
        "compSetIds",
        "isPlusGiven",
        "isTopControlled",
        "isZoneControlled",
        "attemptsToTop",
        "attemptsToZone",
        "attemptsToZones",
        "controlledZone",
        "time",
        "createdAt",
        "judgedAt",
      ])
      .value()
    scoreToSave.scoreBonusIds = compScore.scoreBonuses?.map((sb) => sb._id)
    scoreToSave.judgeId = compScore?.judge?._id
    scoreToSave.competitorId =
      competitorId || compScore?.competitorId || compScore?.competitor?._id
    // if (!scoreToSave.competitorId) {
    //   setMessage("You must select a competitor to submit a score.")
    //   return
    // }
    scoreToSave.compId = compScore.compId || compScore.comp?._id
    scoreToSave.compSetId = compScore.compSetId || compScore.compSet?._id
    scoreToSave.numAttempts = scoreToSave.numAttempts || compScore.attemptsToTop
    scoreToSave.climbId = climb._id
    scoreToSave.climbType = climb.type
    scoreToSave.isFinalScore = !isAutoSaving
    scoreToSave.highpoint = Number(scoreToSave.highpoint || compScore.highpoint)
    if (scoreToSave.judgeId) {
      scoreToSave.userId =
        scoreToSave.userId || compScore?.competitor?.user?._id
    }
    if (
      !scoreToSave.judgeId &&
      !scoreToSave.userId &&
      compSet?.judgeType !== "open"
    ) {
      setMessage(
        `You must select a ${
          !compSet?.judgeType === "judge" ? "competitor" : "witness"
        } to submit a score.`,
      )
      return
    }
    if ((!!isSend || scoreToSave.isCreatingSend) && !compSet.isWorldCupSport) {
      scoreToSave.highpoint = null
    }
    if (
      !isSend &&
      !scoreToSave.isCreatingSend &&
      !compSet.isGradingHolds &&
      !compSet.isWorldCupSport &&
      !compSet.isWorldCupBouldering &&
      !compSet.isWorldCupBoulderingPoints
    ) {
      setMessage(
        "You must log a send in order for your score to count for this round.",
      )
      return false
    }
    if (
      !isSend &&
      !scoreToSave.isCreatingSend &&
      !scoreToSave.highpoint &&
      !compSet.isWorldCupSport &&
      !compSet.isWorldCupBouldering &&
      !compSet.isWorldCupBoulderingPoints
    ) {
      setMessage(`Please enter a highpoint.`)
      return
    }
    if (
      !!scoreToSave.highpoint &&
      ((!compSet.isWorldCupSport &&
        scoreToSave.highpoint > climb?.numHolds - 1) ||
        (compSet.isWorldCupSport && scoreToSave.highpoint > climb?.numHolds))
    ) {
      setMessage(
        compSet.isWorldCupSport
          ? "You've entered in too many holds for this route. "
          : `Your highpoint must be less than ${climb?.numHolds} if you attempted this boulder. If you sent it, click the send button and then record your score.`,
      )
      return
    }

    let returnScore
    try {
      const { data } = await mutation({
        variables: {
          compScore: scoreToSave,
          competitorCode,
        },
        ...(competitorCode
          ? {}
          : {
              refetchQueries: [
                {
                  query: GET_COMP_SCORE_FROM_CLIMB,
                  variables: {
                    compId: scoreToSave.compId,
                    climbId: scoreToSave.climbId,
                    climbType: scoreToSave.climbType,
                    userId: scoreToSave.userId,
                  },
                },
              ],
            }),
      })
      returnScore = isEditing ? data.editCompScore : data.createCompScore
    } catch (err) {
      console.error("err: ", err)
      return
    }
    returnFromSave(returnScore, isAutoSaving)
  }
  return saveCompScore
}

export default useSaveCompScore
