import gql from "graphql-tag"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"
import { characteristicGroupFragment } from "../../gyms/graphql/characteristicGroupFragment"

export const mediumRouteFragment = gql`
  fragment mediumRouteFragment on Route {
    _id
    name
    type
    slug
    totalAscents
    isArchived
    archivedAt
    grade
    setterGrade
    gradeColor
    gradeName
    points
    numUserSends
    numUserAttempts
    createDate
    publishedAt
    startingHoldPicture
    isIndoor
    hasPrintedLabel
    device_mac
    maxCompScores {
      compSet
      maxCompScore
    }
    numHolds
    isSponsored
    grades {
      user
      grade
    }
    location {
      coordinates
    }
    quality
    qualities {
      user
      stars
    }
    color
    pitches {
      name
      grade
    }
    totalAscents
    description
    picture
    overviewPicture
    competitionPicture
    grade
    beta
    height
    gear
    protection
    safety
    numberOfBolts
    kneebar
    sun
    pumpy
    powerful
    crimpy
    slopey
    pinchy
    juggy
    pockety
    bigFeet
    smallFeet
    compression
    tension
    dynamic
    static
    slab
    vertical
    overhang
    steep
    roof
    arete
    dihedral
    crack
    undercling
    heelhooks
    technical
    toprope
    lead
    autobelay
    customCharacteristics
    isDraft
    popMedia {
      ...basicMediaFragment
    }
    setter {
      ...basicUserFragment
    }
    setterPictureLocation {
      x
      y
    }
    holdLocations {
      x
      y
    }
    color
    gym {
      _id
      slug
      type
      name
      isShowingGrades
      isShowingCustomGradesToSetters
      isShowingOutdoorRouteGradeForm
      isFrenchGrades
      isOverlappingGrades
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      isSetterFeedbackOn
      hasScanners
      characteristicGroups {
        ...characteristicGroupFragment
      }
    }
    gymArea {
      _id
      slug
      type
      name
      setterPicture
      popMedia {
        ...basicMediaFragment
      }
    }
    guide {
      _id
      slug
      type
      name
    }
    comps {
      _id
      type
      name
    }
    compSets {
      _id
      type
      name
      start
      end
      isClimbsEnabledBeforeStart
      isGradingHolds
      isRelativeScoring
      relativeScore
      numClimbsBeforeBump
      isGradingAttempts
      attemptPoints
      maxTimesClimbScored
      numClimbsToScore
      numZoneHolds
      judgeType
      pointsPerGradeDiff
      isWorldCupSport
      isWorldCupBouldering
      isWorldCupBoulderingPoints
      comp {
        _id
        type
      }
    }
    judgedSets {
      _id
      type
      name
      start
      end
      isGradingHolds
      isRelativeScoring
      relativeScore
      numClimbsBeforeBump
      isGradingAttempts
      attemptPoints
      maxTimesClimbScored
      numClimbsToScore
      numZoneHolds
      judgeType
      pointsPerGradeDiff
      isWorldCupSport
      isWorldCupBouldering
      isWorldCupBoulderingPoints
    }
    userCompScores {
      _id
      score
      highpoint
      numAttempts
      createdAt
      judgedAt
      comp {
        _id
      }
      compSet {
        _id
      }
      send {
        _id
        slug
      }
      competitor {
        _id
        type
        maxProblemGrade
        maxRouteGrade
        previousMaxRouteGrades {
          grade
          maxDate
        }
        previousMaxProblemGrades {
          grade
          maxDate
        }
      }
    }
    betaVideo {
      ...basicMediaFragment
    }
  }
  ${basicUserFragment}
  ${basicMediaFragment}
  ${characteristicGroupFragment}
`

export default { mediumRouteFragment }
