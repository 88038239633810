import React, { useEffect } from "react"
import { withRouter } from "next/router"
import { useQuery } from "@apollo/client"
import { Flex, Heading, IconButton, useToast } from "@chakra-ui/react"
import { LoggedInUserQuery } from "../../users/graphql/users"
import { NavProvider } from "../../context/NavContext"
import { CompProvider } from "../../gyms/contexts/CompContext"
import Navigation from "./Navigation"
import Footer from "../components/Footer"
import { RouteSetterProvider } from "../../gyms/contexts/RouteSetterContext"
import CookiesConsentBanner from "../../common/components/CookiesConsentBanner"
import Card from "../components/Card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle, faTimes } from "@fortawesome/pro-light-svg-icons"
import { RouteGuard } from "../components/RouteGuard"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { googleSignIn } from "../../config/platforms"
import { ManageCompetitorsProvider } from "../../gyms/contexts/ManageCompetitorsContext"
import GymSidebar from "../../gyms/components/GymSidebar"
import CompSidebar from "../../comps/components/CompSidebar"
import { useAtom } from "jotai"
import { currentGymAtom } from "../../gyms/atoms/gyms"
import { currentCompAtom } from "../../comps/atoms/comps"
import useHasSidebar from "../hooks/useHasSidebar"
import useColors from "../hooks/useColors"
import { AuthProvider, useAuth } from "src/app/common/contexts/AuthContext"

const hideHeaderPaths = [
  "/setter-report",
  "/leaderboard-widget",
  "/setting-schedule-widget",
  "/monthly-setter-reports",
  "/setter-audit-report",
  "/weather",
  "/gyms/leaderboards",
  "/member-dashboards",
]
const hideFooterPaths = [
  "/login",
  "/setter-report",
  "/gympermissions",
  "/leaderboard-widget",
  "/setting-schedule-widget",
  "/monthly-setter-reports",
  "/setter-audit-report",
  "/manage-competitors",
  "/weather",
  "/gyms/leaderboards",
  "/member-dashboards",
]

const SnackToast = ({ message, setMessage, isError, setIsError }) => {
  const { whiteColor, blueColor, orangeColor } = useColors()
  const duration = 5000
  const toast = useToast()
  const snackToastId = message
  useEffect(() => {
    if (message && !toast.isActive(snackToastId)) {
      toast({
        id: snackToastId,
        description: message,
        isClosable: true,
        onCloseComplete: () => {
          setMessage("")
          setIsError(false)
        },
        duration,
        render: ({ onClose }) => (
          <Card
            raised="true"
            borderColor={isError ? orangeColor : blueColor}
            backgroundColor={isError ? orangeColor : whiteColor}
          >
            <Flex align="center" justify="center">
              <FontAwesomeIcon
                size="2x"
                icon={faInfoCircle}
                color={isError ? whiteColor : blueColor}
              />
              <Heading
                flex={1}
                size="sm"
                ml={3}
                mr={3}
                mb={0}
                color={isError ? whiteColor : blueColor}
              >
                {message}
              </Heading>
              <IconButton
                variant="transparent"
                icon={<FontAwesomeIcon size="2x" icon={faTimes} />}
                onClick={() => {
                  setMessage("")
                  onClose()
                }}
                color={isError ? whiteColor : blueColor}
              />
            </Flex>
          </Card>
        ),
      })
    }
  }, [
    blueColor,
    isError,
    message,
    orangeColor,
    setIsError,
    setMessage,
    snackToastId,
    toast,
    whiteColor,
  ])
  return null
}

const App = ({ router, Component, ...pageProps }) => {
  const appRef = React.useRef()
  const isFromApp = React.useRef(router.asPath.includes("isFromApp=true"))
  const [snackbarMessage, setSnackbarMessage] = React.useState("")
  const [isSnackbarError, setIsSnackbarError] = React.useState(false)
  const [currentComp] = useAtom(currentCompAtom)

  // in case router isn't ready
  useEffect(() => {
    if (router.isReady) {
      isFromApp.current = router?.query?.isFromApp
    }
  }, [router])

  const isHidingFooter = hideFooterPaths.some((path) =>
    router.pathname.includes(path),
  )
  const isHidingHeader = hideHeaderPaths.some((path) =>
    router.pathname.includes(path),
  )
  const sidebarType = useHasSidebar(router.pathname)
  const isLogInPage = router.pathname.includes("/login")
  return (
    <div ref={appRef}>
      <div className="app">
        <GoogleOAuthProvider clientId={googleSignIn.clientID}>
          <AuthProvider>
            <NavProvider>
              <RouteSetterProvider>
                <CompProvider>
                  <ManageCompetitorsProvider>
                    {!!isHidingHeader ? null : <Navigation />}
                    {sidebarType === "GYM" && (
                      <GymSidebar
                        isHidingHeader={isHidingHeader}
                        isHidingFooter={isHidingFooter}
                        pathname={router.pathname}
                      />
                    )}

                    {sidebarType === "COMP" && !!currentComp?._id && (
                      <CompSidebar
                        isHidingHeader={isHidingHeader}
                        isHidingFooter={isHidingFooter}
                        comp={currentComp}
                        pathname={router.pathname}
                        setAlertMessage={(msg, isErr) => {
                          setIsSnackbarError(!!isErr)
                          setSnackbarMessage(msg)
                        }}
                      />
                    )}

                    {/* {!isHidingHeader && <AppLinks />} */}
                    <RouteGuard
                      {...pageProps}
                      Component={Component}
                      isHidingHeader={isHidingHeader}
                      setAlertMessage={(msg, isErr) => {
                        setIsSnackbarError(!!isErr)
                        setSnackbarMessage(msg)
                      }}
                    />
                    {!isHidingFooter && <Footer />}
                    {!isHidingHeader && (
                      <SnackToast
                        setMessage={setSnackbarMessage}
                        message={snackbarMessage}
                        isError={isSnackbarError}
                        setIsError={setIsSnackbarError}
                      />
                    )}
                  </ManageCompetitorsProvider>
                </CompProvider>
              </RouteSetterProvider>
            </NavProvider>
          </AuthProvider>
        </GoogleOAuthProvider>
      </div>
      {!isFromApp.current && (!isHidingFooter || isLogInPage) && (
        <CookiesConsentBanner />
      )}
    </div>
  )
}
export default withRouter(App)
