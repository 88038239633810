import gql from "graphql-tag"
import { basicGymFragment } from "./basicGymFragment"
import { basicGymAreaFragment } from "./basicGymAreaFragment"
import { mediumProblemFragment } from "../../guides/graphql/mediumProblemFragment"
import { mediumRouteFragment } from "../../guides/graphql/mediumRouteFragment"
import { basicRouteFragment } from "../../guides/graphql/basicRouteFragment"
import { basicProblemFragment } from "../../guides/graphql/basicProblemFragment"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"
import { fullUserFragment } from "../../users/graphql/users"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import {
  achievementFragment,
  loggedInUserFragment,
} from "../../users/graphql/users"
import { basicScannerActivityFragment } from "../../scanners/graphql/scanners"
import { characteristicGroupFragment } from "./characteristicGroupFragment"
import { characteristicFragment } from "./characteristicFragment"
import { customGymScreenFragment } from "./customGymScreenFragment"
import { basicSendFragment } from "../../sends/graphql/basicSendFragment"
import {
  basicCompFragment,
  compNotificationFragment,
} from "../../gyms/graphql/comps"
import { newsFragment } from "../../feed/graphql/newsFragment"
import { fullFeedItemFragment } from "../../feed/graphql/feed"

export const fullGymFragment = gql`
  fragment fullGymFragment on Gym {
    ...basicGymFragment
    description
    background
    hours
    phone
    website
    address
    managers
    coaches
    headSetters
    setters
    hasMedia
    problemIds
    routeIds
    rgpKey
    rgpUser
    setterFeedbackEmails
    hasMissingClimbEmails
    isSetterFeedbackOn
    setterReportEmails
    missingClimbEmails
    usersToMuteIds
    rgpSetterJobCodes
    rgpFacilityCode
    jwtAPIKey
    isHidden
    isArchived
    firstNotificationDate
    isSendingSettingNotificationsBeforeSettingStarts
    hasScanners
    hasPosts
    isShowingScannerPoints
    commentSubscriptionEmail
    hasPremiumPlan
    notifications {
      _id
      isPermanent
      isPublic
    }
    surveys {
      _id
      slug
      name
      description
      picture
      isTargetedToUser
    }
    overviewMaps {
      _id
    }
    gymGroup {
      _id
      name
      gyms {
        _id
        name
      }
    }
    gymAreas {
      ...basicGymAreaFragment
      parentArea {
        ...basicGymAreaFragment
      }
    }
    canLinkToMRP
    numFollowers
    canUsersMeetup
    meetupActivities {
      _id
      name
      image
    }
    characteristicGroups {
      ...characteristicGroupFragment
    }
    customGymScreens {
      ...customGymScreenFragment
    }
  }
  ${basicGymFragment}
  ${basicGymAreaFragment}
  ${characteristicGroupFragment}
  ${customGymScreenFragment}
`

export const gymPermissionsFragment = gql`
  fragment gymPermissionsFragment on Gym {
    _id
    slug
    name
    managers
    coaches
    headSetters
    setters
    rgpUser
    hasScanners
    hasPremiumPlan
    problemGradeColors {
      color
      name
      minGrade
      maxGrade
      image
    }
    routeGradeColors {
      color
      name
      minGrade
      maxGrade
      image
    }
    ownerUser {
      _id
      slug
      email
      name
      gymsOwned
      gymsManaged
      gymsCoachAt
      gymsEmployeeAt
      gymsHeadSetterAt
      gymsSetterAt
    }
    managerUsers {
      _id
      slug
      email
      name
      gymsOwned
      gymsManaged
      gymsCoachAt
      gymsEmployeeAt
      gymsHeadSetterAt
      gymsSetterAt
      gymsMaxGradesSetAt {
        gymId
        problemGrade
        routeGrade
        canResetArea
      }
    }
    employeeUsers {
      _id
      slug
      email
      name
      gymsOwned
      gymsManaged
      gymsCoachAt
      gymsEmployeeAt
      gymsHeadSetterAt
      gymsSetterAt
      gymsMaxGradesSetAt {
        gymId
        problemGrade
        routeGrade
        canResetArea
      }
    }
    coachUsers {
      _id
      slug
      email
      name
      gymsOwned
      gymsManaged
      gymsCoachAt
      gymsEmployeeAt
      gymsHeadSetterAt
      gymsSetterAt
      gymsMaxGradesSetAt {
        gymId
        problemGrade
        routeGrade
        canResetArea
      }
    }
    headSetterUsers {
      _id
      slug
      email
      name
      firstName
      lastName
      gymsOwned
      gymsCoachAt
      gymsEmployeeAt
      gymsManaged
      gymsHeadSetterAt
      gymsSetterAt
      gymsMaxGradesSetAt {
        gymId
        problemGrade
        routeGrade
        canResetArea
      }
    }
    setterUsers {
      _id
      slug
      email
      name
      firstName
      lastName
      gymsOwned
      gymsCoachAt
      gymsEmployeeAt
      gymsManaged
      gymsHeadSetterAt
      gymsSetterAt
      gymsMaxGradesSetAt {
        gymId
        problemGrade
        routeGrade
        canResetArea
      }
    }
  }
`

export const overviewMapFragment = gql`
  fragment overviewMapFragment on OverviewMap {
    _id
    slug
    imageUrl
    imageSize
    name
    gymId
    gym {
      _id
      slug
      gymAreas {
        _id
        name
      }
    }
    locations {
      gymArea {
        _id
        slug
        name
      }
      coordinates
    }
  }
`
export const gymSetterFragment = gql`
  fragment gymSetterFragment on Gym {
    _id
    name
    isOverlappingGrades
    isShowingGrades
    settingColors {
      color
      name
    }
    problemGradeColors {
      color
      name
      minGrade
      maxGrade
      image
    }
    routeGradeColors {
      color
      name
      minGrade
      maxGrade
      image
    }
    numWeeksForOldProblem
    numWeeksForOldRoute
    setterUsers {
      ...basicUserFragment
    }
    headSetterUsers {
      ...basicUserFragment
    }
  }
`

export const gymNotificationFragment = gql`
  fragment gymNotificationFragment on GymNotification {
    _id
    title
    type
    subtitle
    description
    link
    image
    isPermanent
    expirationDate
    date
    audienceType
    isPublic
    isAllowingComments
    isPushNotification
    isEmailNotification
    creator {
      ...basicUserFragment
    }
    gym {
      ...basicGymFragment
    }
    gyms {
      ...basicGymFragment
    }
    oembed {
      url
      html
      provider_name
      thumbnail_url
    }
    feedItem {
      _id
      slug
      likes {
        ...basicUserFragment
      }
      numComments
    }
    notifiedUsers {
      ...basicUserFragment
    }
    numNotifiedUsers
    isHidingMoreBtn
  }
  ${basicUserFragment}
  ${basicGymFragment}
`

export const labelSettingsFragment = gql`
  fragment labelSettingsFragment on GymLabelSettings {
    _id
    isShowingBelayOptions
    isCreatingMultiplePDFs
    isPrintingAll
    isShowingQRCode
    isShowingGymLogo
    isShowingClimbGrade
    isShowingSetter
    topMargin
    leftMargin
    labelSize
    labelHorizMargin
    labelVertMargin
    isShowingBelayOptions
    isShowingTheFive
  }
`

export const GET_GYM_PERMISSIONS = gql`
  query GetGymPermissions($slug: String!) {
    gym(slug: $slug) {
      ...gymPermissionsFragment
    }
  }
  ${gymPermissionsFragment}
`

export const GET_GYM_LIST = gql`
  query GetGymsList {
    gyms {
      _id
      slug
      name
      type
      isHidden
      popMedia {
        ...basicMediaFragment
      }
    }
  }
  ${basicMediaFragment}
`

export const GET_GYM = gql`
  query GetGym($slug: String!) {
    gym(slug: $slug) {
      ...fullGymFragment
    }
  }
  ${fullGymFragment}
`

export const GET_GYM_BY_ID = gql`
  query GetGymById($gymId: String!) {
    gymById(gymId: $gymId) {
      ...fullGymFragment
    }
  }
  ${fullGymFragment}
`

export const GET_CUSTOM_GYM_SCREENS = gql`
  query GetCustomGymScreen($gymId: String!) {
    gymById(gymId: $gymId) {
      _id
      type
      slug
      customGymScreens {
        ...customGymScreenFragment
      }
    }
  }
  ${customGymScreenFragment}
`

export const GET_GYM_ACTIVITIES = gql`
  query GetGymActivities($slug: String!) {
    gym(slug: $slug) {
      _id
      slug
      scannerActivities {
        ...basicScannerActivityFragment
      }
    }
  }
  ${basicScannerActivityFragment}
`

export const GET_LIST_OF_GYMS = gql`
  query GetListOfGyms($idsString: String!) {
    getListOfGyms(idsString: $idsString) {
      _id
      type
      name
      slug
      popMedia {
        ...basicMediaFragment
      }
      gymAreas {
        _id
        name
        slug
        picture
      }
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
    }
  }
  ${basicMediaFragment}
`

export const GET_GYMS_IN_LIST = gql`
  query GetGymsInList($idsString: String!, $location: PointInput) {
    getListOfGyms(idsString: $idsString, location: $location) {
      _id
      type
      name
      slug
      popMedia {
        ...basicMediaFragment
      }
      location {
        coordinates
      }
      hasProblems
      hasRoutes
      hasScanners
      canLinkToMRP
    }
  }
  ${basicMediaFragment}
`

export const GET_LIST_OF_GYMS_FOR_SCHEDULE = gql`
  query GetListOfGymsForSchedule($idsString: String!) {
    getListOfGyms(idsString: $idsString) {
      _id
      type
      name
      slug
      hasPremiumPlan
      isDailySchedulePushEnabled
      numWeeksForOldProblem
      numWeeksForOldRoute
      popMedia {
        ...basicMediaFragment
      }
      headSetterUsers {
        ...basicUserFragment
        unavailableWorkDays
        isHidingInSettingSchedule
        gymsHeadSetterAt
        gymsSetterAt
        email
        totalHoursAvailablePerWeek
      }
      setterUsers {
        ...basicUserFragment
        unavailableWorkDays
        isHidingInSettingSchedule
        gymsHeadSetterAt
        gymsSetterAt
        email
        totalHoursAvailablePerWeek
      }
      gymAreas {
        _id
        name
        slug
        picture
        oldestClimbDate
        hasProblems
        hasRoutes
        problemConfig {
          numWeeksOld
        }
        routeConfig {
          numWeeksOld
        }
        settingSchedules {
          _id
          type
          startDate
        }
        children {
          _id
        }
      }
    }
  }
  ${basicMediaFragment}
  ${basicUserFragment}
`

export const GET_GYMS_FOR_RECOMMENDATIONS = gql`
  query GetGymsForRecommendations($idsString: String!) {
    getListOfGyms(idsString: $idsString) {
      _id
      type
      name
      slug
      isOverlappingGrades
      isShowingGrades
      popMedia {
        ...basicMediaFragment
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      problemIds
      routeIds
    }
  }
  ${basicMediaFragment}
`

export const GET_GYM_ROUTES = gql`
  query GetGymRoutes($slug: String!) {
    gym(slug: $slug) {
      _id
      slug
      type
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      routes {
        ...mediumRouteFragment
      }
    }
  }
  ${mediumRouteFragment}
`

export const GET_GYM_PROBLEMS = gql`
  query GetGymProblems($slug: String!) {
    gym(slug: $slug) {
      _id
      type
      isOverlappingGrades
      isShowingGrades
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      problems {
        ...mediumProblemFragment
      }
    }
  }
  ${mediumProblemFragment}
`

export const GET_GYM_ROUTES_DIST = gql`
  query GetGymRoutesDist($slug: String!) {
    gym(slug: $slug) {
      _id
      name
      slug
      type
      isOverlappingGrades
      hasPremiumPlan
      hasScanners
      characteristicGroups {
        ...characteristicGroupFragment
      }
      isShowingGrades
      gymAreas {
        _id
        name
        slug
        hasProblems
        hasRoutes
        isExcludedFromDistribution
        children {
          _id
        }
        distribution {
          climbType
          grade
          count
          color
          name
          characteristics {
            climbInd
            climbChars
            customChars
          }
        }
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      routes {
        _id
        type
        slug
        gradeColor
        gradeName
        setterGrade
        isDraft
        customCharacteristics
        gymArea {
          _id
          isExcludedFromDistribution
        }
      }
    }
  }
  ${characteristicGroupFragment}
`

export const GET_GYM_PROBLEMS_DIST = gql`
  query GetGymProblemsDist($slug: String!) {
    gym(slug: $slug) {
      _id
      name
      slug
      type
      isOverlappingGrades
      hasPremiumPlan
      hasScanners
      characteristicGroups {
        ...characteristicGroupFragment
      }
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      gymAreas {
        _id
        name
        slug
        hasProblems
        hasRoutes
        children {
          _id
        }
        isExcludedFromDistribution
        distribution {
          climbType
          grade
          count
          color
          name
          characteristics {
            climbInd
            climbChars
            customChars
          }
        }
      }
      problems {
        _id
        type
        slug
        gradeColor
        gradeName
        setterGrade
        isDraft
        customCharacteristics
        gymArea {
          _id
          isExcludedFromDistribution
        }
      }
    }
  }
  ${characteristicGroupFragment}
`

export const GET_GYM_GRADE_DISTRIBUTION = gql`
  query GetGymGradeDistribution($slug: String!) {
    gym(slug: $slug) {
      _id
      type
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      problems {
        _id
        grade
      }
      routes {
        _id
        grade
      }
    }
  }
`

export const GET_GYM_ROUTE_GRADES = gql`
  query GetGymRouteGrades($slug: String!) {
    gym(slug: $slug) {
      _id
      type
      routes {
        _id
        grade
      }
    }
  }
`

export const GET_GYM_PROBLEM_GRADES = gql`
  query GetGymProblemGrades($slug: String!) {
    gym(slug: $slug) {
      _id
      type
      problems {
        _id
        grade
      }
    }
  }
`

export const GET_GYM_CLIMBS = gql`
  query GetGymClimbs($slug: String!) {
    gym(slug: $slug) {
      _id
      slug
      name
      type
      hasMedia
      isShowingGrades
      isFrenchGrades
      logo
      isOverlappingGrades
      hasRoutes
      hasProblems
      managers
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      problems {
        ...mediumProblemFragment
      }
      routes {
        ...mediumRouteFragment
      }
    }
  }
  ${mediumRouteFragment}
  ${mediumProblemFragment}
`

export const GET_GYM_CLIMB_LABELS = gql`
  query GetGymClimbLabels($slug: String!) {
    gym(slug: $slug) {
      _id
      slug
      name
      type
      hasMedia
      isShowingGrades
      isFrenchGrades
      logo
      isOverlappingGrades
      hasRoutes
      hasProblems
      hasPremiumPlan
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      setterUsers {
        ...basicUserFragment
        lastName
      }
      headSetterUsers {
        ...basicUserFragment
        lastName
      }
      labelSettings {
        ...labelSettingsFragment
      }
      problems {
        ...mediumProblemFragment
        gymArea {
          _id
          type
          name
          slug
        }
      }
      routes {
        ...mediumRouteFragment
        gymArea {
          _id
          type
          name
          slug
        }
      }
    }
  }
  ${mediumProblemFragment}
  ${mediumRouteFragment}
  ${basicUserFragment}
  ${labelSettingsFragment}
`

export const GET_GYM_DETAILS = gql`
  query GetGymDetails($slug: String!) {
    gym(slug: $slug) {
      _id
      slug
      name
      type
      hasMedia
      isShowingGrades
      isFrenchGrades
      logo
      isOverlappingGrades
      hasRoutes
      hasProblems
      # routeGradeColors {
      #   color
      #   name
      #   minGrade
      #   maxGrade
      #   image
      # }
      # problemGradeColors {
      #   color
      #   name
      #   minGrade
      #   maxGrade
      #   image
      # }
      gymAreas {
        ...basicGymAreaFragment
        parentArea {
          ...basicGymAreaFragment
        }
      }
      leaderboardScores {
        problems {
          totalSends
          totalPoints
          top10TotalPoints
        }
        routes {
          totalSends
          totalPoints
          top10TotalPoints
        }
      }
      setterUsers {
        ...basicUserFragment
        lastName
      }
      headSetterUsers {
        ...basicUserFragment
        lastName
      }
      overviewMaps {
        ...overviewMapFragment
      }
      labelSettings {
        ...labelSettingsFragment
      }
    }
  }
  ${basicGymAreaFragment}
  ${basicUserFragment}
  ${overviewMapFragment}
  ${labelSettingsFragment}
`

export const GET_GYM_MEDIA = gql`
  query GetGymMedia($slug: String!) {
    gym(slug: $slug) {
      _id
      slug
      type
      media {
        ...basicMediaFragment
      }
    }
  }
  ${basicMediaFragment}
`
export const GET_ARCHIVED_GYM_MEDIA = gql`
  query GetArchivedGymMedia($slug: String!) {
    gym(slug: $slug) {
      _id
      slug
      type
      archivedMedia {
        ...basicMediaFragment
      }
    }
  }
  ${basicMediaFragment}
`

export const GET_GYM_AREAS = gql`
  query GetGymAreas($slug: String!) {
    gym(slug: $slug) {
      _id
      slug
      type
      name
      isUsageReported
      hasScanners
      hasPremiumPlan
      gymAreas {
        ...basicGymAreaFragment
        isExcludedFromDistribution
        parentArea {
          ...basicGymAreaFragment
        }
        children {
          _id
          name
        }
      }
    }
  }
  ${basicGymAreaFragment}
`

export const GET_GYM_AREAS_FOR_SCHEDULE = gql`
  query GetGymAreasForSchedule($gymId: String!) {
    gymById(gymId: $gymId) {
      _id
      name
      isHidden
      numWeeksForOldRoute
      numWeeksForOldProblem
      gymAreas {
        ...basicGymAreaFragment
        parentArea {
          ...basicGymAreaFragment
        }
        oldestClimbDate
        children {
          _id
        }
      }
    }
  }
  ${basicGymAreaFragment}
`

export const GET_GYM_SETTERS = gql`
  query GetGymSetters($gymId: String!) {
    gymById(gymId: $gymId) {
      _id
      setterUsers {
        ...basicUserFragment
      }
      headSetterUsers {
        ...basicUserFragment
      }
    }
  }
  ${basicUserFragment}
`

export const GET_GYM_NOTIFICATIONS = gql`
  query GetGymNotifications($slug: String!) {
    gym(slug: $slug) {
      _id
      type
      slug
      type
      hasPremiumPlan
      notifications {
        ...gymNotificationFragment
      }
      managers
      coaches
    }
  }
  ${gymNotificationFragment}
`

export const GET_GYM_POSTS = gql`
  query GetGymPosts($_id: String!, $maxDate: Date, $cursor: String) {
    getGymPosts(_id: $_id, maxDate: $maxDate, cursor: $cursor) {
      items {
        ...gymNotificationFragment
      }
      hasMore
      cursor
    }
  }
  ${gymNotificationFragment}
`

export const GET_GYM_MESSAGES = gql`
  query GetGymMessages($_id: String!, $cursor: String) {
    getGymMessages(_id: $_id, cursor: $cursor) {
      items {
        ...gymNotificationFragment
      }
      hasMore
      cursor
    }
  }
  ${gymNotificationFragment}
`

export const GET_SETTER_DRAFT_CLIMBS = gql`
  query GetSetterDraftCimbs($userId: String!) {
    getSetterDraftClimbs(userId: $userId) {
      routes {
        ...mediumRouteFragment
        gymArea {
          _id
          slug
          name
        }
        gym {
          ...gymSetterFragment
        }
      }
      problems {
        ...mediumProblemFragment
        gymArea {
          _id
          slug
          name
        }
        gym {
          ...gymSetterFragment
        }
      }
    }
  }
  ${mediumRouteFragment}
  ${mediumProblemFragment}
  ${gymSetterFragment}
`
export const GET_SETTER_CLIMBS = gql`
  query GetSetterCimbs(
    $userId: String!
    $minDate: Date
    $maxDate: Date
    $isPublished: Boolean
    $gymId: String
    $isDraft: Boolean
  ) {
    getSetterClimbs(
      userId: $userId
      minDate: $minDate
      maxDate: $maxDate
      isPublished: $isPublished
      gymId: $gymId
      isDraft: $isDraft
    ) {
      routes {
        ...mediumRouteFragment
        gymArea {
          _id
          slug
          name
        }
        gym {
          ...gymSetterFragment
        }
        setterFeedback {
          _id
        }
        outdoorGradeFeedback {
          _id
        }
      }
      problems {
        ...mediumProblemFragment
        gymArea {
          _id
          slug
          name
        }
        gym {
          ...gymSetterFragment
        }
        setterFeedback {
          _id
        }
        outdoorGradeFeedback {
          _id
        }
      }
      setters {
        ...basicUserFragment
      }
      gyms {
        ...basicGymFragment
      }
    }
  }
  ${mediumRouteFragment}
  ${mediumProblemFragment}
  ${gymSetterFragment}
  ${basicUserFragment}
  ${basicGymFragment}
`

export const GET_GYMS_DRAFT_CLIMBS = gql`
  query GetGymsDraftCimbs($gymIds: String!) {
    getGymsDraftClimbs(gymIds: $gymIds) {
      routes {
        ...mediumRouteFragment
        gymArea {
          _id
          slug
          name
        }
        gym {
          ...gymSetterFragment
        }
      }
      problems {
        ...mediumProblemFragment
        gymArea {
          _id
          slug
          name
        }
        gym {
          ...gymSetterFragment
        }
      }
    }
  }
  ${mediumRouteFragment}
  ${mediumProblemFragment}
  ${gymSetterFragment}
`

export const analyticsFragment = gql`
  fragment analyticsFragment on AnalyticsData {
    routes {
      _id
      type
      slug
      name
      quality
      qualities {
        user
        stars
      }
      gym {
        _id
      }
      grade
      grades {
        user
        grade
      }
      totalAscents
      totalAttempts
      gradeColor
      gradeName
      setterGrade
      publishedAt
      checkedOutAt
      setter {
        ...basicUserFragment
      }
      setterFeedback {
        _id
        user {
          ...basicUserFragment
        }
        message
        date
      }
      outdoorGradeFeedback {
        _id
        user {
          ...basicUserFragment
        }
        grade
        message
        date
      }
      gear
      picture
      grade
      beta
      height
      protection
      safety
      numberOfBolts
      kneebar
      sun
      pumpy
      powerful
      crimpy
      slopey
      pinchy
      juggy
      pockety
      bigFeet
      smallFeet
      compression
      tension
      dynamic
      static
      slab
      vertical
      overhang
      steep
      roof
      arete
      dihedral
      crack
      undercling
      heelhooks
      technical
      toprope
      lead
      autobelay
    }
    problems {
      _id
      type
      slug
      name
      quality
      qualities {
        user
        stars
      }
      gym {
        _id
      }
      grade
      grades {
        user
        grade
      }
      totalAscents
      gradeColor
      gradeName
      setterGrade
      totalAttempts
      checkedOutAt
      publishedAt
      setter {
        ...basicUserFragment
      }
      setterFeedback {
        _id
        user {
          ...basicUserFragment
        }
        message
        date
      }
      outdoorGradeFeedback {
        _id
        user {
          ...basicUserFragment
        }
        grade
        message
        date
      }
      numberOfSignificantMoves
      height
      start
      crimpy
      slopey
      pinchy
      juggy
      pockety
      undercling
      heelhooks
      toehooks
      kneebar
      bigFeet
      smallFeet
      compression
      tension
      dynamic
      static
      tension
      coordination
      technical
      sun
      slab
      vertical
      overhang
      steep
      roof
      arete
      dihedral
      landing
    }
    timeclockData {
      userId
      inTime
      outTime
      timeclockId
      jobCode
      gym {
        _id
      }
    }
    gyms {
      _id
      slug
      name
      isOverlappingGrades
      hasPremiumPlan
      hasScanners
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
    }
  }
  ${basicUserFragment}
`

export const GET_ROUTESETTING_ANALYTICS = gql`
  query GetRoutesettingAnalytics(
    $queryFilters: RoutesettingAnalyticsInput!
    $key: String
  ) {
    getRoutesettingAnalytics(queryFilters: $queryFilters, key: $key) {
      ...analyticsFragment
    }
  }
  ${analyticsFragment}
`

export const EDIT_ANALYTICS_POINT = gql`
  mutation EditAnalyticsPoint($point: AnalyticsPointInput!) {
    editAnalyticsPoint(point: $point) {
      timeclockId
      inTime
      outTime
      jobCode
      climbs {
        _id
        checkedOutAt
        publishedAt
      }
    }
  }
`

export const GET_RGP_FACILITY_CODES = gql`
  query GetRGPFacilityCodes(
    $rgpUser: String!
    $rgpKey: String!
    $gymId: String!
  ) {
    getRGPFacilityCodes(rgpUser: $rgpUser, rgpKey: $rgpKey, gymId: $gymId) {
      codes
    }
  }
`

export const GET_RGP_EMPLOYEES = gql`
  query GetRGPEmployees($gymId: String!) {
    getRGPEmployees(gymId: $gymId) {
      employees {
        customerGuid
        firstName
        lastName
        email
      }
      setterRGPEmployeeLinks {
        setterId
        customerGuid
        isIgnoring
      }
    }
  }
`

export const LINK_SETTERS_TO_RGP_EMPLOYEES = gql`
  mutation LinkSettersToRGPEmployees(
    $setterRGPEmployeeLinks: SetterRGPEmployeeLinksInput!
    $gymId: String!
    $rgpFacilityCode: String!
  ) {
    linkSettersToRGPEmployees(
      setterRGPEmployeeLinks: $setterRGPEmployeeLinks
      gymId: $gymId
      rgpFacilityCode: $rgpFacilityCode
    ) {
      ...fullGymFragment
    }
  }
  ${fullGymFragment}
`

export const SAVE_LATEST_RGP_TIMECLOCK_DATA = gql`
  mutation SaveLatestRGPTimeclockData(
    $gymId: String!
    $setterId: String
    $page: Int
    $isFromBeginning: Boolean
    $jobCodes: String
  ) {
    saveLatestRGPTimeclockData(
      gymId: $gymId
      setterId: $setterId
      page: $page
      isFromBeginning: $isFromBeginning
      jobCodes: $jobCodes
    ) {
      data {
        _id
      }
      error {
        setterId
        page
        url
      }
    }
  }
`

export const EDIT_GYM = gql`
  mutation EditGym($gym: GymInput!) {
    editGym(gym: $gym) {
      ...fullGymFragment
    }
  }
  ${fullGymFragment}
`

export const CREATE_GYM = gql`
  mutation CreateGym($gym: GymInput!) {
    createGym(gym: $gym) {
      ...fullGymFragment
    }
  }
  ${fullGymFragment}
`

export const SAVE_GYM_PERMISSIONS = gql`
  mutation SaveGymPermissions($permissions: UserPermissions!, $slug: String!) {
    saveGymPermissions(permissions: $permissions, slug: $slug) {
      ...fullUserFragment
    }
  }
  ${fullUserFragment}
`

export const FOLLOW_GYM = gql`
  mutation FollowGym($gymId: String, $userId: String) {
    followGym(gymId: $gymId, userId: $userId) {
      ...fullUserFragment
    }
  }
  ${fullUserFragment}
`

export const UNFOLLOW_GYM = gql`
  mutation UnfollowGym($gymId: String, $userId: String) {
    unfollowGym(gymId: $gymId, userId: $userId) {
      ...fullUserFragment
    }
  }
  ${fullUserFragment}
`

export const ASSIGN_PROBLEM_TO_SETTER = gql`
  mutation AssignProblemToSetter($problemId: String!, $setterId: String!) {
    assignProblemToSetter(problemId: $problemId, setterId: $setterId) {
      ...mediumProblemFragment
    }
  }
  ${mediumProblemFragment}
`

export const ASSIGN_ROUTE_TO_SETTER = gql`
  mutation AssignRouteToSetter($routeId: String!, $setterId: String!) {
    assignRouteToSetter(routeId: $routeId, setterId: $setterId) {
      ...mediumRouteFragment
    }
  }
  ${mediumRouteFragment}
`

export const UNASSIGN_SETTER_FROM_PROBLEM = gql`
  mutation UnassignSetterFromProblem($problemId: String!, $setterId: String!) {
    unassignSetterFromProblem(problemId: $problemId, setterId: $setterId) {
      ...mediumProblemFragment
    }
  }
  ${mediumProblemFragment}
`

export const UNASSIGN_SETTER_FROM_ROUTE = gql`
  mutation UnassignSetterFromRoute($routeId: String!, $setterId: String!) {
    unassignSetterFromRoute(routeId: $routeId, setterId: $setterId) {
      ...mediumRouteFragment
    }
  }
  ${mediumRouteFragment}
`

export const PUBLISH_PROBLEM = gql`
  mutation PublishProblem($problemId: String!) {
    publishProblem(problemId: $problemId) {
      ...mediumProblemFragment
    }
  }
  ${mediumProblemFragment}
`

export const PUBLISH_ROUTE = gql`
  mutation PublishRoute($routeId: String!) {
    publishRoute(routeId: $routeId) {
      ...mediumRouteFragment
    }
  }
  ${mediumRouteFragment}
`

export const UNPUBLISH_PROBLEM = gql`
  mutation UnpublishProblem($problemId: String!) {
    unpublishProblem(problemId: $problemId) {
      ...mediumProblemFragment
    }
  }
  ${mediumProblemFragment}
`

export const UNPUBLISH_ROUTE = gql`
  mutation UnpublishRoute($routeId: String!) {
    unpublishRoute(routeId: $routeId) {
      ...mediumRouteFragment
    }
  }
  ${mediumRouteFragment}
`

export const ASSIGN_PROBLEM_A_COLOR = gql`
  mutation AssignProblemAColor(
    $problemId: String!
    $color: String!
    $isPublishing: Boolean
    $name: String
  ) {
    assignProblemAColor(
      problemId: $problemId
      name: $name
      color: $color
      isPublishing: $isPublishing
    ) {
      ...mediumProblemFragment
    }
  }
  ${mediumProblemFragment}
`

export const ASSIGN_ROUTE_A_COLOR = gql`
  mutation AssignRouteAColor(
    $routeId: String!
    $color: String!
    $isPublishing: Boolean
    $name: String
  ) {
    assignRouteAColor(
      routeId: $routeId
      name: $name
      color: $color
      isPublishing: $isPublishing
    ) {
      ...mediumRouteFragment
    }
  }
  ${mediumRouteFragment}
`

// export const SAVE_PROBLEM_QR_CODE = gql`
//   mutation SaveProblemQRCode($problemId: String!, $qrCode: String!) {
//     saveProblemQRCode(problemId: $problemId, qrCode: $qrCode) {
//       ...mediumProblemFragment
//     }
//   }
//   ${mediumProblemFragment}
// `

// export const SAVE_ROUTE_QR_CODE = gql`
//   mutation SaveRouteQRCode($routeId: String!, $qrCode: String!) {
//     saveRouteQRCode(routeId: $routeId, qrCode: $qrCode) {
//       ...mediumRouteFragment
//     }
//   }
//   ${mediumRouteFragment}
// `

export const CHECK_HAS_LINKED_CLIMB_TO_SCANNER = gql`
  query CheckHasLinkedClimbToScanner($climbId: String!, $climbType: String!) {
    checkHasLinkedClimbToScanner(climbId: $climbId, climbType: $climbType) {
      isSuccessful
      error
    }
  }
`

export const CHANGE_PROBLEM_GRADE = gql`
  mutation ChangeProblemGrade(
    $problemId: String!
    $grade: Float!
    $name: String!
  ) {
    changeProblemGrade(problemId: $problemId, grade: $grade, name: $name) {
      ...mediumProblemFragment
    }
  }
  ${mediumProblemFragment}
`

export const CHANGE_ROUTE_GRADE = gql`
  mutation ChangeRouteGrade($routeId: String!, $grade: Float!, $name: String!) {
    changeRouteGrade(routeId: $routeId, grade: $grade, name: $name) {
      ...mediumRouteFragment
    }
  }
  ${mediumRouteFragment}
`

export const RESEND_GYM_PERMISSION = gql`
  mutation ResendGymPermission($gymId: String!, $userId: String!) {
    resendGymPermission(userId: $userId, gymId: $gymId) {
      ...basicUserFragment
    }
  }
  ${basicUserFragment}
`

export const REORDER_AREAS_IN_GYM = gql`
  mutation ReorderAreasInGym($areaOrder: AreaOrderInput!, $gymId: String!) {
    reorderAreasInGym(areaOrder: $areaOrder, gymId: $gymId) {
      ...fullGymFragment
    }
  }
  ${fullGymFragment}
`

export const RENAME_ALL_CLIMBS = gql`
  mutation RenameAllClimbs($gymId: String!) {
    renameAllClimbs(gymId: $gymId) {
      problems {
        _id
        name
      }
      routes {
        _id
        name
      }
    }
  }
`

export const CHECK_GYM_MEDIA = gql`
  mutation CheckGymMedia($slug: String!) {
    checkGymMedia(slug: $slug) {
      ...fullGymFragment
    }
  }
  ${fullGymFragment}
`

export const CREATE_GYM_NOTIFICATION = gql`
  mutation CreateGymNotification($gymNotification: GymNotificationInput!) {
    createGymNotification(gymNotification: $gymNotification) {
      _id
      notifications {
        ...gymNotificationFragment
      }
    }
  }
  ${basicUserFragment}
  ${gymNotificationFragment}
`

export const EDIT_GYM_NOTIFICATION = gql`
  mutation EditGymNotification($gymNotification: GymNotificationInput!) {
    editGymNotification(gymNotification: $gymNotification) {
      _id
      title
      subtitle
      description
      link
      image
      isPermanent
      expirationDate
      creator {
        ...basicUserFragment
      }
      oembed {
        url
        html
        provider_name
        thumbnail_url
      }
      feedItemSlug
    }
  }
  ${basicUserFragment}
`

export const DELETE_GYM_NOTIFICATION = gql`
  mutation DeleteGymNotification($id: String!) {
    deleteGymNotification(id: $id) {
      _id
      notifications {
        ...gymNotificationFragment
      }
    }
  }
  ${gymNotificationFragment}
  ${basicUserFragment}
`

export const GET_OVERVIEW_MAP = gql`
  query GetOverviewMap($slug: String!) {
    overviewMap(slug: $slug) {
      ...overviewMapFragment
    }
  }
  ${overviewMapFragment}
`

export const GET_GYM_OVERVIEW_MAPS = gql`
  query GetGymOverviewMaps($slug: String!) {
    gym(slug: $slug) {
      _id
      slug
      overviewMaps {
        ...overviewMapFragment
      }
    }
  }
  ${overviewMapFragment}
`

export const CREATE_OVERVIEW_MAP = gql`
  mutation CreateOverviewMap($overviewMap: OverviewMapInput!) {
    overviewMap: createOverviewMap(overviewMap: $overviewMap) {
      ...overviewMapFragment
    }
  }
  ${overviewMapFragment}
`

export const EDIT_OVERVIEW_MAP = gql`
  mutation EditOverviewMap($overviewMap: OverviewMapInput!) {
    overviewMap: editOverviewMap(overviewMap: $overviewMap) {
      ...overviewMapFragment
    }
  }
  ${overviewMapFragment}
`

export const DELETE_OVERVIEW_MAP = gql`
  mutation DeleteOverviewMap($overviewMapId: String!) {
    overviewMap: deleteOverviewMap(overviewMapId: $overviewMapId) {
      ...overviewMapFragment
    }
  }
  ${overviewMapFragment}
`

export const SEND_SETTER_FEEDBACK = gql`
  mutation SendSetterFeedback(
    $climbSlug: String!
    $climbType: String!
    $message: String!
    $isImportant: Boolean
    $isSpinner: Boolean
  ) {
    sendSetterFeedback(
      climbSlug: $climbSlug
      climbType: $climbType
      message: $message
      isImportant: $isImportant
      isSpinner: $isSpinner
    ) {
      isSuccessful
      error
    }
  }
`

export const GENERATE_JWT_API_KEY = gql`
  mutation GenerateJWTAPIKey($gymId: String!) {
    generateJWTAPIKey(gymId: $gymId) {
      _id
      jwtAPIKey
    }
  }
`

export const LINK_MRP_ACCOUNT = gql`
  mutation LinkMRPAccount(
    $gymId: String
    $gymGroupId: String
    $barcode: String!
    $dob: String!
    $employeeId: String
  ) {
    linkMRPAccount(
      gymId: $gymId
      gymGroupId: $gymGroupId
      barcode: $barcode
      dob: $dob
      employeeId: $employeeId
    ) {
      ...loggedInUserFragment
    }
  }
  ${loggedInUserFragment}
`

export const UNLINK_MRP_ACCOUNT = gql`
  mutation UnlinkMRPAccount($gymId: String!) {
    unlinkMRPAccount(gymId: $gymId) {
      ...loggedInUserFragment
    }
  }
  ${loggedInUserFragment}
`

export const GET_MEMBER_REWARD_BADGES = gql`
  query GetMemberRewardBadges(
    $gymId: String!
    $isSkippingPebbleBadges: Boolean
  ) {
    getMemberRewardBadges(
      gymId: $gymId
      isSkippingPebbleBadges: $isSkippingPebbleBadges
    ) {
      userBadges {
        ...achievementFragment
      }
      allBadges {
        ...achievementFragment
      }
      pebbleBadges {
        ...achievementFragment
      }
    }
  }
  ${achievementFragment}
`

export const LOG_GYM_MEMBER_ACTIVITY = gql`
  mutation LogGymMemberActivity(
    $gymId: String
    $gymGroupId: String
    $activityId: String!
  ) {
    logGymMemberActivity(
      gymId: $gymId
      gymGroupId: $gymGroupId
      activityId: $activityId
    ) {
      _id
      message
      rewards {
        _id
        pointsEarned
        description
        quantity
      }
    }
  }
`

export const GET_GYMS_MANAGED_AT = gql`
  query GetGymsManagedAt {
    getGymsManagedAt {
      ...basicGymFragment
    }
  }
  ${basicGymFragment}
`

export const GET_GYMS_USER_SETS_OR_MANAGES = gql`
  query GetGymsUserSetsOrManages {
    getGymsUserSetsOrManages {
      ...basicGymFragment
      gymAreas {
        ...basicGymAreaFragment
      }
    }
  }
  ${basicGymFragment}
  ${basicGymAreaFragment}
`

export const GET_GYM_FOLLOWERS = gql`
  query GetGymFollowers($_id: String!, $searchStr: String, $cursor: String) {
    getGymFollowers(_id: $_id, searchStr: $searchStr, cursor: $cursor) {
      cursor
      hasMore
      followers {
        ...basicUserFragment
      }
    }
  }
  ${basicUserFragment}
`

export const CHARACTERISTIC_GROUP = gql`
  query CharacteristicGroup($gymSlug: String!, $groupId: String!) {
    characteristicGroup(gymSlug: $gymSlug, groupId: $groupId) {
      ...characteristicGroupFragment
      problems {
        ...mediumProblemFragment
      }
      routes {
        ...mediumRouteFragment
      }
    }
  }
  ${characteristicGroupFragment}
  ${mediumProblemFragment}
  ${mediumRouteFragment}
`

export const CUSTOM_CHARACTERISTIC_CLIMBS = gql`
  query CustomCharacteristicClimbs(
    $gymSlug: String!
    $characteristicId: String!
    $groupId: String!
  ) {
    customCharacteristicClimbs(
      gymSlug: $gymSlug
      characteristicId: $characteristicId
      groupId: $groupId
    ) {
      ...characteristicFragment
      problems {
        ...mediumProblemFragment
      }
      routes {
        ...mediumRouteFragment
      }
    }
  }
  ${characteristicFragment}
  ${mediumProblemFragment}
  ${mediumRouteFragment}
`

export const MUTE_USER = gql`
  mutation MuteUser($userId: String!, $gymId: String!) {
    muteUser(userId: $userId, gymId: $gymId) {
      ...basicGymFragment
    }
  }
  ${basicGymFragment}
`

export const UNMUTE_USER = gql`
  mutation UnmuteUser($userId: String!, $gymId: String!) {
    unmuteUser(userId: $userId, gymId: $gymId) {
      ...basicGymFragment
    }
  }
  ${basicGymFragment}
`

export const SEND_TEST_PUSH_NOTIFICATION = gql`
  mutation SendTestPushNotification(
    $gymId: String!
    $title: String!
    $message: String
    $url: String
  ) {
    sendTestPushNotification(
      gymId: $gymId
      title: $title
      message: $message
      url: $url
    ) {
      isSuccessful
      error
    }
  }
`

export const SEND_TEST_EMAIL_GYM_NOTIFICATION = gql`
  mutation SendTestEmailGymNotification(
    $gymId: String!
    $title: String!
    $message: String
    $url: String
    $image: String
    $videoURL: String
    $videoThumbnail: String
  ) {
    sendTestEmailGymNotification(
      gymId: $gymId
      title: $title
      message: $message
      url: $url
      image: $image
      videoURL: $videoURL
      videoThumbnail: $videoThumbnail
    ) {
      isSuccessful
      error
    }
  }
`

export const PUBLIC_GYM_SURVEYS = gql`
  query PublicGymSurveys($slug: String!) {
    gym(slug: $slug) {
      _id
      surveys {
        _id
        name
        description
        picture
        slug
        isTargetedToUser
      }
    }
  }
`

export const SAVE_GYM_LABEL_SETTINGS = gql`
  mutation SaveGymLabelSettings(
    $gymId: String!
    $labelSettings: GymLabelSettingsInput!
  ) {
    saveGymLabelSettings(gymId: $gymId, labelSettings: $labelSettings) {
      _id
      labelSettings {
        ...labelSettingsFragment
      }
    }
  }
  ${labelSettingsFragment}
`

export const UNSUBSCRIBE_FROM_GYM_NOTIFICATIONS = gql`
  mutation UnsubscribeFromGymNotifications(
    $gymId: String
    $userId: String
    $isResubscribing: Boolean
  ) {
    unsubscribeFromGymNotifications(
      gymId: $gymId
      userId: $userId
      isResubscribing: $isResubscribing
    ) {
      isSuccessful
      error
    }
  }
`

export const GIVE_OUTDOOR_GRADE_FEEDBACK = gql`
  mutation GiveOutdoorGradeFeedback(
    $grade: Float!
    $climbType: String!
    $climbId: String!
    $message: String!
  ) {
    giveOutdoorGradeFeedback(
      climbType: $climbType
      climbId: $climbId
      grade: $grade
      message: $message
    ) {
      isSuccessful
      error
    }
  }
`

export const AMENITIES_ATTENDANCE_CHALLENGE_LEADERBOARD = gql`
  query AmenitiesAttendanceChallengeLeaderboard(
    $location: String
    $activity: String
  ) {
    amenitiesAttendanceChallengeLeaderboard(
      location: $location
      activity: $activity
    ) {
      _id
      leaders {
        _id
        rank
        name
        attendanceCount
        user {
          ...basicUserFragment
        }
      }
    }
  }
  ${basicUserFragment}
`

export const RGP_BARCODE = gql`
  query RGPBarcode($gymId: String) {
    rgpBarcode(gymId: $gymId) {
      barcode
    }
  }
`

export const STAFF_SEARCH = gql`
  query StaffSearch($searchStr: String!) {
    staffSearch(searchStr: $searchStr) {
      ...basicUserFragment
    }
  }
  ${basicUserFragment}
`

export const GYM_MEMBER_DASHBOARD = gql`
  query GymMemberDashboard($slug: String!) {
    gym(slug: $slug) {
      _id
      slug
      name
      background
      type
      hasPremiumPlan
      hasScanners
      hasProblems
      hasRoutes
      popMedia {
        ...basicMediaFragment
      }
      settingColors {
        color
        name
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      latestResetAreas {
        ...basicGymAreaFragment
      }
      popularProblems {
        ...basicProblemFragment
        gymArea {
          ...basicGymAreaFragment
        }
        setter {
          ...basicUserFragment
        }
      }
      popularRoutes {
        ...basicRouteFragment
        gymArea {
          ...basicGymAreaFragment
        }
        setter {
          ...basicUserFragment
        }
      }
      dashboardLeaderboard {
        rank
        user {
          ...basicUserFragment
        }
        score
      }
      hardestProblemSends {
        ...basicSendFragment
        problem {
          ...basicProblemFragment
          gymArea {
            ...basicGymAreaFragment
          }
        }
      }
      hardestRouteSends {
        ...basicSendFragment
        route {
          ...basicRouteFragment
          gymArea {
            ...basicGymAreaFragment
          }
        }
      }
      gymDashboardNews {
        ...newsFragment
        ...gymNotificationFragment
        ...compNotificationFragment
        ...basicCompFragment
      }
      gymDashboardFeed {
        ...fullFeedItemFragment
      }
    }
  }
  ${basicMediaFragment}
  ${basicGymAreaFragment}
  ${basicProblemFragment}
  ${basicRouteFragment}
  ${basicUserFragment}
  ${basicSendFragment}
  ${basicGymAreaFragment}
  ${newsFragment}
  ${gymNotificationFragment}
  ${compNotificationFragment}
  ${basicCompFragment}
  ${fullFeedItemFragment}
`
