import * as React from "react"
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  List,
  ListItem,
  Box,
  Portal,
  Button,
  Flex,
  useMediaQuery,
  useTheme,
  DrawerFooter,
} from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import NextLink from "next/link"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { hasSidebarAtom } from "../atoms/sidebar"
import { useAtom } from "jotai"
import useColors from "../../common/hooks/useColors"

const SidebarContent = ({ sections, isDrawer = false, onClose }) => {
  const { orangeColor, blueColor } = useColors()
  return (
    <Box mx={isDrawer ? -6 : 0}>
      {sections.map((section, index) => (
        <Box key={index}>
          {!!section.title && (
            <Heading ml={6} as="h3" size="md" mt={5}>
              {section.title}
            </Heading>
          )}
          <List>
            {section.items.map((item, index) => {
              if (item.variant === "divider") {
                return (
                  <Box
                    key={index}
                    my={8}
                    borderBottomWidth={1}
                    borderColor="brand.headingLineColor"
                  />
                )
              }
              if (item.variant === "error") {
                return (
                  <Heading
                    mx={6}
                    key={item.title}
                    size="xs"
                    fontStyle="italic"
                    color="brand.orangeColor"
                  >
                    {item.title}
                  </Heading>
                )
              }
              if (item.items) {
                return (
                  <SidebarContent
                    onClose={onClose}
                    sections={[item]}
                    key={index}
                  />
                )
              }
              const LinkCont = item.href ? NextLink : React.Fragment
              const isActive = item.href === window.location.pathname
              return (
                <ListItem key={index} onClick={onClose}>
                  <Flex align="center">
                    <LinkCont
                      {...(item.href
                        ? {
                            href: item.href,
                            passHref: true,
                          }
                        : {})}
                    >
                      <Button
                        color={
                          isActive ? "brand.orangeColor" : "brand.blueColor"
                        }
                        py={3}
                        height="auto"
                        whiteSpace={"normal"}
                        as={item.href ? "a" : "button"}
                        flex={1}
                        justifyContent={"flex-start"}
                        textAlign={"left"}
                        borderRadius={0}
                        variant="text"
                        onClick={item.onClick}
                        isLoading={item.isLoading}
                        leftIcon={
                          <Box mr={2}>
                            <FontAwesomeIcon
                              icon={item.icon}
                              color={isActive ? orangeColor : blueColor}
                            />
                          </Box>
                        }
                        sx={{
                          "& .chakra-button__icon:nth-of-type(2)": {
                            marginLeft: "auto",
                            paddingLeft: 2,
                          },
                        }}
                        rightIcon={item.RightComponent}
                      >
                        {item.title}
                      </Button>
                    </LinkCont>
                  </Flex>
                </ListItem>
              )
            })}
          </List>
        </Box>
      ))}
    </Box>
  )
}

type ItemType = {
  title: string
  icon: IconDefinition
  href?: string
  onClick?: () => void
  isLoading?: boolean
  RightComponent?: React.ReactNode
  variant?: "error"
}

type SectionType = {
  title?: string
  items: (ItemType | SectionType)[]
}

export interface ISidebarProps {
  isOpen?: boolean
  title?: string
  titleHref?: string
  onClose?: () => void
  isSidebar?: boolean
  headerComponent?: React.ReactNode
  sections: SectionType[]
  isHidingHeader?: boolean
  isHidingFooter?: boolean
  footerComponent?: React.ReactNode
}

const Title = ({ title, titleHref, onClose }) => {
  return (
    <Heading size="md" px={4} pt={4} variant={titleHref ? "link" : null}>
      <NextLink href={titleHref}>
        <Box
          onClick={onClose}
          as="span"
          color={
            titleHref === window.location.pathname
              ? "brand.orangeColor"
              : "brand.blueColor"
          }
        >
          {title}
        </Box>
      </NextLink>
    </Heading>
  )
}

export default function DrawerComp({
  isOpen,
  title,
  titleHref,
  onClose = () => {},
  sections,
  headerComponent,
  isHidingHeader = true,
  isHidingFooter = true,
  footerComponent = null,
}: ISidebarProps) {
  const [isSidebarBig] = useMediaQuery("(min-width: 1450px)")
  const [isSidebarSmall] = useMediaQuery("(min-width: 1250px)")
  const isSidebar = isSidebarBig || isSidebarSmall
  const [, setHasSidebar] = useAtom(hasSidebarAtom)

  React.useEffect(() => {
    setHasSidebar(sections?.length > 0)
  }, [sections?.length, setHasSidebar])

  if (!sections?.length) {
    return null
  }
  if (isSidebar) {
    return (
      <Box
        left={0}
        top={isHidingHeader ? 0 : "60px"}
        width={isSidebarBig ? "280px" : "200px"}
        bottom={0}
        position="fixed"
        bg={"brand.whiteColor"}
        boxShadow={"0 0 10px 0 rgba(0,0,0,0.2)"}
        zIndex={3}
        display="flex"
        flexDirection="column"
      >
        <Box>{headerComponent}</Box>
        {title && (
          <Title title={title} titleHref={titleHref} onClose={onClose} />
        )}
        <Box flex={1} overflow={"auto"} pb={isHidingFooter ? 0 : "140px"}>
          <SidebarContent sections={sections} onClose={onClose} />
          {footerComponent}
        </Box>
      </Box>
    )
  }
  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bg="brand.whiteColor">
        <DrawerCloseButton />
        <Box mt={4}>{headerComponent}</Box>
        {title && (
          <Title title={title} titleHref={titleHref} onClose={onClose} />
        )}
        <DrawerBody>
          <SidebarContent isDrawer sections={sections} onClose={onClose} />
        </DrawerBody>
        {!!footerComponent && <DrawerFooter>{footerComponent}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  )
}
