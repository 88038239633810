import { variant } from "styled-system"
import colors, {
  brandColorsWeightedLight,
  brandColorsWeightedDark,
} from "../../config/colors"
import { isDarkModeAtom } from "./useColors"
import { useAtom } from "jotai"

export default function useChakraTheme() {
  const [isDarkMode] = useAtom(isDarkModeAtom)

  const brandColors = colors[isDarkMode ? "dark" : "light"]
  const brandColorsWeighted = isDarkMode
    ? brandColorsWeightedDark
    : brandColorsWeightedLight
  const chakraTheme = {
    initialColorMode: isDarkMode ? "dark" : "light",
    useSystemColorMode: false,
    styles: {
      global: {
        "html, body": {
          color: "brand.headingColor",
        },
        li: {
          fontSize: "1.1rem",
        },
        a: {
          color: "brand.blueColor",
        },
      },
    },
    colors: {
      brand: brandColors,
      brandWeighted: brandColorsWeighted,
    },
    fonts: {
      logo: `'Comfortaa', Helvetica, Arial, sans-serif`,
      heading: `'Manrope', Helvetica, Arial, sans-serif`,
      body: `'Open Sans', Helvetica, Arial, sans-serif`,
    },
    // this isn't working
    borderRadius: {
      radii: {
        none: "0",
        sm: "0.125rem",
        base: "1rem",
        md: "1rem",
        lg: "1.25rem",
        xl: "2rem",
        "2xl": "3rem",
        "3xl": "4rem",
        full: "9999px",
      },
    },
    components: {
      ListItem: {
        baseStyle: {
          my: 1,
        },
      },
      Modal: {
        sizes: {
          xl: {
            width: "80%",
          },
        },
      },
      // not working
      // Card: {
      //   variants: {
      //     input: {
      //       borderColor: headingLineColor,
      //       borderWidth: 1,
      //     },
      //     outline: {
      //       borderColor: headingLineColor,
      //       borderWidth: 1,
      //     },
      //   },
      // },
      Avatar: {
        sizes: {
          md: {
            width: "50px",
            height: "50px",
          },
        },
        variants: {
          raised: {
            boxShadow: "0px 1px 5px rgb(0 0 0 / 10%)",
          },
          square: {
            borderRadius: 0,
          },
        },
      },
      Link: {
        baseStyle: {
          color: "brand.blueColor",
          textDecoration: "none",
          _hover: {
            textDecoration: "underline",
          },
          _focus: {
            boxShadow: "none",
          },
        },
      },
      LinkOverlay: {
        baseStyle: {
          color: "brand.blueColor",
          textDecoration: "none",
          _hover: {
            textDecoration: "underline",
          },
          _focus: {
            boxShadow: "none",
          },
        },
      },
      Tabs: {
        variants: {
          line: {
            tab: {
              color: "brand.blueColor",
              borderColor: "brand.blueColor",
              fontWeight: "bold",
              letterSpacing: "0.05em",
              _selected: {
                borderColor: "brand.orangeColor",
                color: "brand.orangeColor",
                boxShadow: "none",
              },
            },
            tablist: {
              borderColor: "brand.blueColor",
            },
          },
        },
      },
      Drawer: {
        variants: {
          permanent: {
            dialogContainer: {
              width: 0,
            },
          },
        },
      },
      IconButton: {
        variants: {
          transparent: {
            backgroundColor: "transparent",
            _hover: {
              backgroundColor: "brands.whiteOverlay",
            },
          },
        },
      },
      Input: {
        variants: {
          outline: {
            field: {
              borderColor: "brand.blueColor",
            },
          },
        },
      },
      Textarea: {
        variants: {
          outline: {
            borderColor: "brand.blueColor",
          },
        },
      },
      List: {
        baseStyle: {
          fontSize: "1.3rem",
        },
        sizes: {
          md: {
            fontSize: "1.3rem",
          },
        },
      },
      Tag: {
        variants: {
          error: (props) => {
            return {
              container: {
                bg: "brand.orangeColor",
                fontWeight: "bold",
                color: "white",
                borderRadius: "3xl",
              },
              label: {
                fontWeight: "bold",
              },
            }
          },
        },
      },
      Heading: {
        baseStyle: (props) => ({
          mb: 2,
          fontWeight: props.islight ? "normal" : "bold",
          fontStyle: props.italic ? "italic" : "normal",
        }),
        variants: {
          link: (props) => ({
            color: "brand.blueColor",
            cursor: "pointer",
            _hover: {
              textDecoration: props.isunderline ? "underline" : "none",
            },
          }),
          error: (props) => ({
            color: "brand.orangeColor",
          }),
        },
      },
      Text: {
        baseStyle: (props) => ({
          mb: 5,
          color: "brand.headingColor",
        }),
      },
      Divider: {
        baseStyle: {
          borderColor: "brand.headingLineColor",
          my: 5,
        },
      },
      Stat: {
        baseStyle: {
          label: {
            fontFamily: "heading",
            color: "brand.headingColor",
          },
          helpText: {
            fontFamily: "heading",
            color: "brand.headingColor",
          },
          number: {
            fontFamily: "heading",
            color: "brand.headingColor",
          },
          icon: {
            fontFamily: "heading",
            color: "brand.headingColor",
          },
        },
      },
      Checkbox: {
        baseStyle: {
          icon: {
            // color: 'white',
          },
          control: {
            border: "1px",
            borderColor: "brand.blueColor",
            borderRadius: "base",
            _disabled: {
              borderColor: "gray.300",
              bg: "gray.200",
            },
            _checked: {
              bg: "brand.orangeColor",
              borderColor: "brand.orangeColor",
              _hover: {
                bg: "brand.orangeColor",
                borderColor: "brand.orangeColor",
              },
            },
            _hover: {
              bg: "brand.orangeColor",
              borderColor: "brand.blueColor",
            },
          },
          label: {
            fontWeight: "medium",
            lineHeight: "1.3rem",
          },
          container: {
            display: "flex",
            pt: 2,
            pb: 4,
            pr: 6,
          },
        },
      },
      Radio: {
        baseStyle: {
          icon: {
            // color: 'white',
          },
          control: {
            border: "1px",
            borderColor: "brand.blueColor",
            _disabled: {
              borderColor: "gray.300",
              bg: "gray.200",
            },
            _checked: {
              bg: "brand.orangeColor",
              borderColor: "brand.orangeColor",
              _hover: {
                bg: "brand.orangeColor",
                borderColor: "brand.orangeColor",
              },
            },
            _hover: {
              bg: "brand.orangeColor",
              borderColor: "brand.blueColor",
            },
          },
          label: {
            fontWeight: "medium",
            color: "brand.headingColor",
            lineHeight: "1.3rem",
          },
          container: {
            display: "flex",
            pt: 2,
            pb: 4,
            pr: 6,
          },
        },
      },
      Switch: {
        baseStyle: {
          icon: {
            // color: 'white',
          },
          track: {
            // border: "1px",
            // borderColor: "brand.headingColor",
            bg: "brand.headingLineColor",
            _disabled: {
              borderColor: "gray.300",
              bg: "gray.200",
            },
            _checked: {
              bg: "brand.orangeColor",
              borderColor: "brand.orangeColor",
              _hover: {
                bg: "brand.orangeColor",
                borderColor: "brand.orangeColor",
              },
            },
            _hover: {
              bg: "brand.orangeColor",
              borderColor: "brand.blueColor",
            },
          },
          label: {
            fontWeight: "medium",
            color: "brand.headingColor",
            lineHeight: "1.3rem",
          },
          container: {
            display: "flex",
            pt: 2,
            pb: 4,
            pr: 6,
          },
        },
      },
      Button: {
        // 1. We can update the base styles
        baseStyle: {
          // letterSpacing: "0.05rem",
          fontSize: "1.2rem",
          color: "brand.blueColor",
          borderRadius: "3xl",
          fontFamily: "heading",
          _focus: {
            boxShadow: "none",
          },
        },
        // 2. We can add a new button size or extend existing
        sizes: {
          sm: {
            borderRadius: "2xl",
            px: "1rem",
          },
          md: {
            px: "1.5rem",
          },
        },
        variants: {
          primary: (props) => ({
            color: "brand.whiteColor",
            background: "brand.blueColor",
            boxShadow: props.raised ? "0px 1px 5px rgb(0 0 0 / 10%)" : "",
            _hover: {
              background: "brand.blueColorHover",
            },
            _active: {
              transform: "scale(0.98)",
            },
            _focus: {
              boxShadow: "0 1px 1px rgba(0, 0, 0, .15)",
            },
          }),
          "primary-shadow": (props) => ({
            color: "brand.whiteColor",
            background: "brand.blueColor",
            boxShadow: "0px 1px 5px rgb(0 0 0 / 10%)",
            _hover: {
              background: "brand.blueColorHover",
            },
            _active: {
              transform: "scale(0.98)",
            },
            _focus: {
              boxShadow: "0 1px 1px rgba(0, 0, 0, .15)",
            },
          }),
          secondary: (props) => ({
            color: "brand.blueColor",
            background: "brand.whiteColor",
            border: "1px solid",
            borderColor: props.raised
              ? "brand.headingLineColor"
              : "brand.headingLineColor",
            boxShadow: props.raised ? "0px 1px 5px rgb(0 0 0 / 10%)" : "",
            _hover: {
              background: "brand.whiteColorHover",
            },
            _active: {
              transform: "scale(0.98)",
            },
            _focus: {
              boxShadow: "0 1px 1px rgba(0, 0, 0, .15)",
            },
          }),
          facebook: (props) => ({
            background: "#3a5998",
            alignSelf: "stretch",
            color: "brand.whiteColor",
            my: "0.5rem",
            _hover: {
              background: "#3a5998",
            },
          }),
          google: (props) => ({
            background: "#d84a38",
            alignSelf: "stretch",
            color: "brand.whiteColor",
            my: "0.5rem",
            _hover: {
              background: "#d84a38",
            },
          }),
          apple: (props) => ({
            background: "#000",
            color: "brand.whiteColor",
            alignSelf: "stretch",
            my: "0.5rem",
            _hover: {
              background: "#000",
            },
          }),
          text: (props) => ({
            color: "brand.blueColor",
            background: "transparent",
            borderWidth: "1px",
            borderColor: "transparent",
            px: "1.5rem",
            // marginTop: "-0.13rem",
            boxShadow: props.raised ? "0 1px 2px rgba(0, 0, 0, 0.2)" : "",
            _hover: {
              background: "brand.whiteColorHover",
            },
            _active: {
              transform: "scale(0.98)",
            },
          }),
          "text-underline": (props) => ({
            color: "brand.blueColor",
            background: "transparent",
            px: ".5rem",
            marginTop: "-0.13rem",
            boxShadow: props.raised ? "0 1px 2px rgba(0, 0, 0, 0.2)" : "",
            _hover: {
              textDecoration: "underline",
            },
            _active: {
              transform: "scale(0.98)",
            },
          }),
          input: (props) => ({
            borderRadius: "md",
            border: "1px solid",
            borderColor: "gray.200",
            px: ".5rem",
          }),
          outline: {
            borderRadius: "3xl",
            color: "brand.blueColor",
            borderColor: "brand.blueColor",
            _hover: {
              background: "rgba(0, 0, 0, 0.1)",
            },
          },
          ghost: {
            borderRadius: "3xl",
            color: "brand.blueColor",
            _hover: {
              background: "rgba(0, 0, 0, 0.1)",
            },
          },
        },
      },
      Table: {
        variants: {
          striped: {
            tr: {
              borderColor: "brand.headingLineColor",
            },
            th: {
              color: "brand.headingColor",
              fontWeight: "bold",
              borderColor: "brand.headingLineColor",
            },
            td: {
              borderColor: "brand.headingLineColor",
              color: "brand.headingColor",
            },
            tbody: {
              tr: {
                "&:nth-of-type(odd)": {
                  td: {
                    borderColor: "transparent",
                    background: "brand.bgColor",
                  },
                },
              },
            },
          },
        },
      },
      MenuList: {
        baseStyle: {
          borderRadius: "3xl",
        },
      },
    },
  }
  return {
    chakraTheme,
    isDarkMode,
  }
}
